import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Typography, Button, Dropdown, Tabs } from "antd";
import _get from "lodash/get";

import { StoreColumn } from "components/Columns";
import { ROLES, STORE_TYPE } from "utils/constants";
import { useQuery } from "hooks";
import TabHeader from "components/TabHeader";
import { IconSeeMore } from "assets/images";
import {
  getStoreListRequest,
  setSelectedIds,
} from "providers/StoreProvider/slice";
import { Table } from "components/common";
import MenuOverlay from "./MenuOverlay";
import MenuAllOverlay from "./MenuAllOverlay";
import SearchBar from "./SearchBar";
import "./styles.less";

const { TabPane } = Tabs;

const context = {
  [STORE_TYPE.CAR_AND_OLD_STUFF]: {
    title: "Danh sách xe - đồ cũ",
    tabs: ["", "CAR", "OLD_STUFF", true, "pin"],
    createBtnText: "Tạo cửa hàng",
  },
  [STORE_TYPE.TRAVEL]: {
    title: "Danh sách du lịch",
    tabs: ["", true, "pin"],
    createBtnText: "Đăng bài",
  },
  [STORE_TYPE.SHIPPER]: {
    title: "Danh sách dịch vụ vận chuyển",
    tabs: ["", true, "pin"],
    createBtnText: "Tạo dịch vụ vận chuyển",
  },
  [STORE_TYPE.ENTERTAINMENT]: {
    title: "Danh sách giải trí",
    tabs: ["", "karaoke", "game", "club", "other", true, "pin"],
    createBtnText: "Tạo giải trí",
  },
};

const StoreList = ({ storeType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { list, total, isLoading, selectedRowKeys, selectedRows } = useSelector(
    (state) => state.store
  );

  const storeContext = context[storeType];

  const [searchObject, setSearch] = useQuery(getStoreListRequest, {
    arrayParams: ["status"],
    customParameter: {
      type: storeType,
    },
  });
  const roleCurrentUser = useSelector((state) =>
    _get(state, "auth.currentUser.role")
  );
  const isAdminPermission = [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(
    roleCurrentUser
  );

  const storeIdList = [];
  const storeNameList = [];
  selectedRows.map((item) => storeIdList.push(item._id));
  selectedRows.map((item) => storeNameList.push(item.name));

  const isDisableLockBtn = !!selectedRows.find(
    (item) => item.status === "lock"
  );
  const isDisableUnlockBtn = !!selectedRows.find(
    (item) => item.status === "active"
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedIds({
          record,
          selected,
          selectedRows: _selectedRows,
        })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedIds({
          selected,
          selectedRows: _selectedRows,
          changeRows,
        })
      );
    },
  };

  const refetchList = () => {
    dispatch(
      getStoreListRequest({
        ...searchObject,
      })
    );
  };

  const columns = StoreColumn({ storeType });

  const extraColumns = () => {
    const extra = [];
    if (storeType === STORE_TYPE.TRAVEL) {
      extra.push(columns.location);
    }
    if (storeType === STORE_TYPE.CAR_AND_OLD_STUFF) {
      extra.push(columns.type);
    }
    return extra;
  };

  return (
    <div className="store-list-wrapper">
      <Row className="store-list-header" align="middle" justify="space-between">
        <Typography.Title level={4}>{storeContext.title}</Typography.Title>
        <Button
          size="large"
          type="primary"
          onClick={() =>
            navigate(`${location.pathname}/create`, {
              state: { canGoBack: true },
            })
          }
        >
          {storeContext.createBtnText}
        </Button>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Tabs
          className="store-tabs-container"
          activeKey={searchObject.subType}
          type="card"
          onChange={(key) => {
            setSearch(
              {
                subType: key,
                keyword: searchObject.keyword,
                status: searchObject.status,
                activeStatus: searchObject.activeStatus,
              },
              true
            );
          }}
        >
          {storeContext.tabs.map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={<TabHeader status={value} total={100} />}
              key={value}
            >
              <Table
                rowSelection={rowSelection}
                scroll={{ x: "max-content" }}
                loading={isLoading}
                bordered
                columns={[
                  columns.id,
                  columns.accountHolder,
                  columns.name,
                  columns.address,
                  ...extraColumns(),
                  columns.rating,
                  columns.status,
                  columns.pin,
                  columns.isPromotionActive,
                  columns.rewardBalance,
                  {
                    title: (
                      <Dropdown
                        overlay={
                          <MenuAllOverlay
                            isAdminPermission={isAdminPermission}
                            isDisableUnlockBtn={isDisableUnlockBtn}
                            isDisableLockBtn={isDisableLockBtn}
                            refetchList={refetchList}
                            selectedRowKeys={selectedRowKeys}
                            storeNameList={storeNameList}
                          />
                        }
                        placement="bottomLeft"
                        arrow={isAdminPermission}
                      >
                        <Button
                          size="large"
                          className="see-more-btn"
                          type="ghost"
                          icon={<img src={IconSeeMore} alt="see-more" />}
                        />
                      </Dropdown>
                    ),
                    width: 100,
                    render: (record) => (
                      <Dropdown
                        overlay={
                          <MenuOverlay
                            record={record}
                            isAdminPermission={isAdminPermission}
                            refetchList={refetchList}
                          />
                        }
                        placement="bottomLeft"
                        arrow
                      >
                        <Button
                          className="see-more-btn"
                          type="ghost"
                          icon={<img src={IconSeeMore} alt="see-more" />}
                        />
                      </Dropdown>
                    ),
                  },
                ]}
                dataSource={list}
                rowKey={(data) => data._id}
                total={total}
                onRow={(record) => ({
                  onDoubleClick: () => {
                    navigate(`${location.pathname}/${record._id}`, {
                      state: { canGoBack: true },
                    });
                  },
                })}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default StoreList;
